const Action = {
  // Game
  PLAY: 'PLAY',
  RESET: 'RESET',
  TICK: 'TICK',
  GAME_OVER: 'GAME_OVER',
  MOVE: 'MOVE',
  MOVE_FINISHED: 'MOVE_FINISHED',
  CHANGE_DIRECTION: 'CHANGE_DIRECTION',
  SPAWN_FOOD: 'SPAWN_FOOD',
  EAT_FOOD: 'EAT_FOOD',
  // Game config
  SET_SIZE: 'SET_SIZE',
  SET_FPS: 'SET_FPS',
  SET_SPEED: 'SET_SPEED',
  SET_FRAME_TIMEOUT: 'SET_FRAME_TIMEOUT',
  TOGGLE_WALLS_ARE_FATAL: 'TOGGLE_WALLS_ARE_FATAL',
  // High Score
  ADD_SCORE: 'ADD_SCORE',
  // Path Finding,
  START_PATH_FIND: 'START_PATH_FIND',
  FINISH_PATH_FIND: 'FINISH_PATH_FIND',
  PATH_NOT_FOUND: 'PATH_NOT_FOUND',
  IGNORE_STALE_RESULT: 'IGNORE_STALE_RESULT',
  // AI config
  SET_ALGORITHM: 'SET_ALGORITHM',
  TOGGLE_SHOW_PATH: 'TOGGLE_SHOW_PATH',
  CHANGE_NAME: 'CHANGE_NAME',
  ALGORITHMS: {
    greedy: 'greedy',
    astar: 'astar',
    none: 'none',
  },
};

export default Action;
